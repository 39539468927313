/* global tw */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { width as twWidth } from '../../tailwind';

const Wrapper = styled.svg`
  ${tw('stroke-current absolute')};
  color: ${props => props.stroke};
  width: ${props => props.svgWidth};
  fill: ${props => props.fill};
  left: ${props => props.left};
  top: ${props => props.top};
`;

const icons = {
  triangle: {
    shape: (
      <polygon
        strokeWidth="1px"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="14.921,2.27 28.667,25.5 1.175,25.5 "
      />
    ),
    viewBox: '0 0 30 30',
  },
  circle: {
    shape: (
      <path d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,6.23A8.77,8.77,0,1,0,23.77,15,8.77,8.77,0,0,0,15,6.23Z" />
    ),
    viewBox: '0 0 30 30',
  },
  arrowUp: {
    shape: (
      <React.Fragment>
        <path d="M28.74,20.81H1.26a1.26,1.26,0,0,1-1-2L14.16.5a1.25,1.25,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,18.8a1.25,1.25,0,0,1-1,2ZM3.81,18.29H26.22L15.16,3.37Z" />{' '}
        <path d="M28.74,42H1.26a1.28,1.28,0,0,1-1.13-.71A1.26,1.26,0,0,1,.26,40l13.9-18.29a1.28,1.28,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,40a1.26,1.26,0,0,1,.12,1.32A1.28,1.28,0,0,1,28.74,42ZM3.81,39.47H26.22L15.16,24.55Z" />
      </React.Fragment>
    ),
    viewBox: '0 0 30 42',
  },
  upDown: {
    shape: (
      <React.Fragment>
        <path d="M28.74,44.58a1.28,1.28,0,0,1-1-.51L15.16,27.13l-12.89,17a1.26,1.26,0,1,1-2-1.53l13.9-18.29a1.34,1.34,0,0,1,1-.5,1.24,1.24,0,0,1,1,.51L29.75,42.56a1.27,1.27,0,0,1-1,2Z" />
        <path d="M14.83,20.82h0a1.28,1.28,0,0,1-1-.52L.25,2a1.27,1.27,0,0,1,2-1.51L14.84,17.45,27.73.5a1.26,1.26,0,0,1,2,1.53L15.84,20.32A1.28,1.28,0,0,1,14.83,20.82Z" />
      </React.Fragment>
    ),
    viewBox: '0 0 30 44.58',
  },
  box: {
    shape: (
      <path d="M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z" />
    ),
    viewBox: '0 0 30 30',
  },
  hexa: {
    shape: (
      <polygon
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 "
      />
    ),
    viewBox: '0 0 30 30',
  },
  gameboy: {
    shape: (
      <svg>
        <rect id="case" x="0.5" y="0.5" width="57" height="92" rx="4"></rect>
        <path d="M7.03514732,7 C5.90808436,7 5,7.91598372 5,9.05678991 L5,41.940036 C5,43.0808424 5.90808436,44 7.03514732,44 L14.034298,44 L44.8249167,44 L46.2109492,44 C49.944526,44 52.9498269,40.9580524 52.9498269,37.1789483 L52.9498269,34.9698036 C52.9804335,34.8440885 53,34.7117643 53,34.5762203 L53,8.62511795 C53,7.72473171 52.2839994,7 51.3944601,7 L44.8249167,7 L7.79087999,7 L7.03514732,7 Z" id="bevel"></path>
        <rect id="screen" x="14" y="11" width="30" height="28"></rect>
        <path d="M9,21.4999997 C9,21.7761421 8.77614209,22 8.49999967,22 C8.22385791,22 8,21.7761421 8,21.4999997 C8,21.2238579 8.22385791,21 8.49999967,21 C8.77614209,21 9,21.2238579 9,21.4999997 Z" id="power-light"></path>
        <path d="M44,64.5000006 C44,66.4329969 42.4329958,68 40.4999994,68 C38.5670031,68 37,66.4329969 37,64.5000006 C37,62.5670042 38.5670031,61 40.4999994,61 C42.4329958,61 44,62.5670042 44,64.5000006 Z" id="b"></path>
        <path d="M53,60.5000006 C53,62.4329969 51.4329958,64 49.4999994,64 C47.5670031,64 46,62.4329969 46,60.5000006 C46,58.5670042 47.5670031,57 49.4999994,57 C51.4329958,57 53,58.5670042 53,60.5000006 Z" id="a"></path>
        <path d="M9.72690852,56 C9.55537528,56 9.41842867,56.1400626 9.41842867,56.3115958 L9.41842867,60.4215446 L5.30847986,60.4215446 C5.13694661,60.4215446 5,60.5584912 5,60.7300245 L5,65.2730915 C5,65.4446247 5.13694661,65.5815713 5.30847986,65.5815713 L9.41842867,65.5815713 L9.41842867,69.6915201 C9.41842867,69.8630534 9.55537528,70 9.72690852,70 L14.2699755,70 C14.4415088,70 14.5815713,69.8630534 14.5815713,69.6915201 L14.5815713,65.5815713 L18.6915201,65.5815713 C18.8630534,65.5815713 19,65.4446247 19,65.2730915 L19,60.7300245 C19,60.5584912 18.8630534,60.4215446 18.6915201,60.4215446 L14.5815713,60.4215446 L14.5815713,56.3115958 C14.5815713,56.1400626 14.4415088,56 14.2699755,56 L9.72690852,56 Z" id="d-pad"></path>
        <polygon id="start" transform="translate(30.397821, 76.964930) rotate(-27.128161) translate(-30.397821, -76.964930) " points="26.7941168 76.1149057 34.0065033 76.093953 34.0015245 77.8149542 26.7891379 77.8359069"></polygon>
        <polygon id="select" transform="translate(20.771851, 77.029563) rotate(-27.128161) translate(-20.771851, -77.029563) " points="17.1681473 76.1795389 24.3805339 76.1585862 24.375555 77.8795874 17.1631685 77.9005401"></polygon>
      </svg>
    ),
    viewBox: '0 0 58 93'
  },
  pokeball: {
    shape: (
      <React.Fragment>
        <circle id="Oval" stroke-width="3" cx="30" cy="30" r="30"></circle>
        <circle id="Oval" stroke-width="3" cx="30" cy="30" r="9"></circle>
        <circle id="Oval" stroke-width="0" cx="30" cy="30" r="4"></circle>
        <path d="M0,30 L21,30" id="Line" stroke-width="3"></path>
        <path d="M40,30 L61,30" id="Line" stroke-width="3"></path>
      </React.Fragment>
    ),
    viewBox: '-2 -2 65 65'
  },
  controller: {
    shape: (
      <React.Fragment>
      <path d="M24.6745407,0.0371130848 L24.6745407,0 L96.1994751,0 L96.1994751,0.203588913 C109.055025,1.81154283 119,12.7479496 119,26 C119,40.3594035 107.323563,52 92.9199475,52 C81.294004,52 71.4448128,44.4161694 68.0784308,33.9401198 L50.9215692,33.9401198 C47.5551872,44.4161694 37.705996,52 26.0800525,52 C11.6764372,52 0,40.3594035 0,26 C0,12.1106874 10.9244368,0.765081759 24.6745202,0.0371141677 Z" id="case"></path>
      <circle id="x" cx="82.5" cy="27.5" r="4.5"></circle>
      <circle id="y" cx="94.5" cy="17.5" r="4.5"></circle>
      <circle id="a" cx="93.5" cy="37.5" r="4.5"></circle>
      <circle id="b" cx="105.5" cy="27.5" r="4.5"></circle>
      <path d="M27.8,15.5 L22.2,15.5 L22.2,23.2 L14.5,23.2 L14.5,28.8 L22.2,28.8 L22.2,36.5 L27.8,36.5 L27.8,28.8 L35.5,28.8 L35.5,23.2 L27.8,23.2 L27.8,15.5 Z" id="d-pad"></path>
      <rect id="start" x="51.5" y="19.5" width="6" height="1"></rect>
      <rect id="select" x="61.5" y="19.5" width="6" height="1"></rect>
      </React.Fragment>
    ),
    viewBox: '0 0 119 52'
  }
};

const SVG = ({ stroke, fill, width, icon, left, top, className }) => (
  <Wrapper
    viewBox={icons[icon].viewBox}
    stroke={stroke}
    fill={fill}
    svgWidth={twWidth[`${width}`]}
    left={left}
    top={top}
    className={className}
  >
    {icons[icon].shape}
  </Wrapper>
);

export default SVG;

SVG.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  icon: PropTypes.oneOf(['triangle', 'circle', 'arrowUp', 'upDown', 'box', 'hexa', 'gameboy', 'pokeball']).isRequired,
  left: PropTypes.string,
  top: PropTypes.string,
  className: PropTypes.string,
};

SVG.defaultProps = {
  stroke: 'transparent',
  width: 8,
  fill: 'none',
  left: '0%',
  top: '0%',
  className: 'fancy-icon',
};
